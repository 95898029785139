export const economicsFormCurrency = {
	dolar: {
		opciones:{
			precio: {
				htmlType: 'input',
				label: 'Precio',
				config: {
					id: 'precioDolar',
					type: 'number',
					placeholder: 'Precio del dolar',
					required: true
				},
				value: ''
			},
			cambio: {
				htmlType: 'input',
				label: 'Cambio en pesos',
				config: {
					id: 'cambioDolar',
					type: 'number',
					placeholder: 'Cambio del dolar en pesos',
					required: true
				},
				value: ''
			},
			cambioP: {
				htmlType: 'input',
				label: 'Cambio en porcentaje',
				config: {
					id: 'cambioDolarPorcentaje',
					type: 'number',
					placeholder: 'Cambio del dolar en porcentaje',
					required: true
				},
				value: ''
			},
		}
	},
	euro: {
		opciones:{
			precio: {
				htmlType: 'input',
				label: 'Precio',
				config: {
					id: 'precioEuro',
					type: 'number',
					placeholder: 'Precio del Euro',
					required: true
				},
				value: ''
			},
			cambio: {
				htmlType: 'input',
				label: 'Cambio en pesos',
				config: {
					id: 'cambioEuro',
					type: 'number',
					placeholder: 'Cambio del euro en pesos',
					required: true
				},
				value: ''
			},
			cambioP: {
				htmlType: 'input',
				label: 'Cambio en porcentaje',
				config: {
					id: 'cambioEuroPorcentaje',
					type: 'number',
					placeholder: 'Cambio del euro en porcentaje',
					required: true
				},
				value: ''
			},
		}		
	}
}

export const economicsFormStockExchange = {
	acciones:{
		opciones: {
			ecopetrol: {
				precio: {
					htmlType: 'input',
					label: 'Precio Ecopetrol',
					config: {
						id: 'precioEcopetrol',
						type: 'number',
						placeholder: 'Valor Ecopetrol',
						required: true
					},
					value: ''
				},
				cambio: {
					htmlType: 'input',
					label: 'Cambio en dolares Ecopetrol',
					config: {
						id: 'cambioEcopetrol',
						type: 'number',
						placeholder: 'Cambio del valor en dolares',
						required: true
					},
					value: ''
				},
				cambioP: {
					htmlType: 'input',
					label: 'Cambio en porcentaje Ecopetrol',
					config: {
						id: 'cambioEcopetrolPorcentaje',
						type: 'number',
						placeholder: 'Cambio del valor en porcentaje',
						required: true
					},
					value: ''
				},
				image: {
					htmlType: 'radio',
					config: {
						id: 'radioEcopetrol',
						options: [
							{ label: 'Subió', type: 'radio', value: 'up', id: 'subeEcopetrol', name: 'radioEcopetrol' },
							{ label: 'Bajó', type: 'radio', value: 'down', id: 'bajaEcopetrol', name: 'radioEcopetrol' },
							{ label: 'Estable', type: 'radio', value: 'equal', id: 'igualEcopetrol', name: 'radioEcopetrol' }
						]
					},
					value: 'radioEcopetrol'
				}
			},
			pfbColombia: {
				precio: {
					htmlType: 'input',
					label: 'Precio PFBColombia',
					config: {
						id: 'preciopfb',
						type: 'number',
						placeholder: 'Valor PFB Colombia',
						required: true
					},
					value: ''
				},
				cambio: {
					htmlType: 'input',
					label: 'Cambio en dolares PFBColombia',
					config: {
						id: 'cambioPfb',
						type: 'number',
						placeholder: 'Cambio del valor en dolares',
						required: true
					},
					value: ''
				},
				cambioP: {
					htmlType: 'input',
					label: 'Cambio en porcentaje PFBColombia',
					config: {
						id: 'cambioPfbPorcentaje',
						type: 'number',
						placeholder: 'Cambio del valor en porcentaje',
						required: true
					},
					value: ''
				},
				image: {
					htmlType: 'radio',
					config: {
						id: 'radioPfb',
						options: [
							{ label: 'Subió', type: 'radio', value: 'up', id: 'subePfb', name: 'radioPfb' },
							{ label: 'Bajó', type: 'radio', value: 'down', id: 'bajaPfb', name: 'radioPfb' },
							{ label: 'Estable', type: 'radio', value: 'equal', id: 'igualPfb', name: 'radioPfb' }
						]
					},
					value: 'radioPfb'
				}
			},
			isa: {
				precio: {
					htmlType: 'input',
					label: 'Precio ISA',
					config: {
						id: 'precioIsa',
						type: 'number',
						placeholder: 'Valor ISA',
						required: true
					},
					value: ''
				},
				cambio: {
					htmlType: 'input',
					label: 'Cambio en dolares ISA',
					config: {
						id: 'cambioIsa',
						type: 'number',
						placeholder: 'Cambio del valor en dolares',
						required: true
					},
					value: ''
				},
				cambioP: {
					htmlType: 'input',
					label: 'Cambio en porcentaje ISA',
					config: {
						id: 'cambioIsaPorcentaje',
						type: 'number',
						placeholder: 'Cambio del valor en porcentaje',
						required: true
					},
					value: ''
				},
				image: {
					htmlType: 'radio',
					config: {
						id: 'radioIsa',
						options: [
							{ label: 'Subió', type: 'radio', value: 'up', id: 'subeIsa', name: 'radioIsa' },
							{ label: 'Bajó', type: 'radio', value: 'down', id: 'bajaIsa', name: 'radioIsa' },
							{ label: 'Estable', type: 'radio', value: 'equal', id: 'igualIsa', name: 'radioIsa' }
						]
					},
					value: 'radioIsa'
				}
			},
			gruposura: {
				precio: {
					htmlType: 'input',
					label: 'Precio SURA',
					config: {
						id: 'precioSura',
						type: 'number',
						placeholder: 'Valor Grupo Sura',
						required: true
					},
					value: ''
				},
				cambio: {
					htmlType: 'input',
					label: 'Cambio en dolares SURA',
					config: {
						id: 'cambioSura',
						type: 'number',
						placeholder: 'Cambio del valor en dolares',
						required: true
					},
					value: ''
				},
				cambioP: {
					htmlType: 'input',
					label: 'Cambio en porcentaje SURA',
					config: {
						id: 'cambioSuraPorcentaje',
						type: 'number',
						placeholder: 'Cambio del valor en porcentaje',
						required: true
					},
					value: ''
				},
				image: {
					htmlType: 'radio',
					config: {
						id: 'radioSura',
						options: [
							{ label: 'Subió', type: 'radio', value: 'up', id: 'subeSura', name: 'radioSura' },
							{ label: 'Bajó', type: 'radio', value: 'down', id: 'bajaSura', name: 'radioSura' },
							{ label: 'Estable', type: 'radio', value: 'equal', id: 'igualSura', name: 'radioSura' }
						]
					},
					value: 'radioSura'
				}
			},
			bcolombia: {
				precio: {
					htmlType: 'input',
					label: 'Precio BCOLOMBIA',
					config: {
						id: 'precioColombia',
						type: 'number',
						placeholder: 'Valor BColombia',
						required: true
					},
					value: ''
				},
				cambio: {
					htmlType: 'input',
					label: 'Cambio en dolares BCOLOMBIA',
					config: {
						id: 'cambioColombia',
						type: 'number',
						placeholder: 'Cambio del valor en dolares',
						required: true
					},
					value: ''
				},
				cambioP: {
					htmlType: 'input',
					label: 'Cambio en porcentaje BCOLOMBIA',
					config: {
						id: 'cambioColombiaPorcentaje',
						type: 'number',
						placeholder: 'Cambio del valor en porcentaje',
						required: true
					},
					value: ''
				},
				image: {
					htmlType: 'radio',
					config: {
						id: 'radioColombia',
						options: [
							{ label: 'Subió', type: 'radio', value: 'up', id: 'subeColombia', name: 'radioColombia' },
							{ label: 'Bajó', type: 'radio', value: 'down', id: 'bajaColombia', name: 'radioColombia' },
							{ label: 'Estable', type: 'radio', value: 'equal', id: 'igualColombia', name: 'radioColombia' }
						]
					},
					value: 'radioColombia'
				}
			}
		}
	}
}