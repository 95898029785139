import React, { Component } from 'react';
import { Form, Button, Card, Row, Col, Modal } from 'react-bootstrap'
import Input from '../../components/Forms/InputType'
import Layout from '../../wrapperAux/wrapper'
import { economicsFormCurrency, economicsFormStockExchange } from './formStructure'
import axios from 'axios'

class Economics extends Component {

	state = {
		economicsFormCurrency,
		economicsFormStockExchange,
		validated: false,
		showModal: false,
		disabled: true
	}

	componentDidMount() {
		axios.get(`${process.env.REACT_APP_BASE_API}/economy/display`)
			.then(res => this.fillDataForms(Object.keys(res.data), res.data))
			.catch(err => err)
	}

	fillDataForms = (arrData, data) => {		
		const form = {
			...this.state.economicsFormCurrency
		}
		const form2 = {
			...this.state.economicsFormStockExchange
		}		

		for (const el of arrData) {
			if ((el !== '_id' && el !== '__v') && (el === 'dolar' || el === 'euro')) {
				form[el].opciones.cambio.value = data[el].opciones.cambio;
				form[el].opciones.cambioP.value = data[el].opciones.cambioP;
				form[el].opciones.precio.value = data[el].opciones.precio;	
			}else if (el === 'acciones') {
				for (const op of Object.keys(data[el].opciones)){					
					form2[el].opciones[op].cambio.value = data[el].opciones[op].cambio;
					form2[el].opciones[op].cambioP.value = data[el].opciones[op].cambioP;
					form2[el].opciones[op].precio.value = data[el].opciones[op].precio;
					if (data[el].opciones[op].image==='up') {
						form2[el].opciones[op].image.config.options[0].defaultChecked=true;
					}else if (data[el].opciones[op].image==='down') {
						form2[el].opciones[op].image.config.options[1].defaultChecked=true;
					}else{
						form2[el].opciones[op].image.config.options[2].defaultChecked=true;
					}
				}				
			}

		}		
		this.setState({ 
			economicsFormCurrency: form,
			economicsFormStockExchange: form2
		});
			
	}

	handleSubmit = (event) => {
		const form = event.currentTarget
		event.preventDefault();
		if (form.checkValidity() === false) {
			event.stopPropagation()
		} else {
			let opciones = {}
			const dataForm = {}
			for (const info in this.state.economicsFormCurrency) {
				for (const input in this.state.economicsFormCurrency[info]["opciones"]) {
					opciones[input] = this.state.economicsFormCurrency[info]["opciones"][input].value
					dataForm[info] = {
						opciones: {
							...opciones
						}
					}
				}
			}
			opciones = {}
			let tipos = {}
			for (const info in this.state.economicsFormStockExchange) {
				for (const input in this.state.economicsFormStockExchange[info]["opciones"]) {
					for (const option in this.state.economicsFormStockExchange[info]["opciones"][input]) {
						tipos[option] = this.state.economicsFormStockExchange[info]["opciones"][input][option].value
						opciones[input] = {
							...tipos
						}
						dataForm[info] = {
							opciones: {
								...opciones
							}
						}
					}
					tipos = {}
				}
				opciones = {}
			}
			axios.post(`${process.env.REACT_APP_BASE_API}/economy/edit`, dataForm)
				.then(res => {
					if (res.status === 200) {
						this.setState({ showModal: true })
					}
				})
		}
		this.setState({ validated: true })
	}

	handleChangeCurrency = (event, keyParent, key) => {
		const form = {
			...this.state.economicsFormCurrency
		}
		const formElementParent = {
			...form[keyParent]
		}
		const formElement = {
			...formElementParent["opciones"][key]
		}
		formElement.value = event.target.value
		formElementParent["opciones"][key] = formElement
		form[keyParent] = formElementParent
		this.setState({ economicsFormCurrency: form })
	}

	handleChangeStock = (event, keyRoot, keyParent, key) => {
		const form = {
			...this.state.economicsFormStockExchange
		}
		const formElementRoot = {
			...form[keyRoot]
		}
		const formElementParent = {
			...formElementRoot["opciones"][keyParent]
		}
		const formElement = {
			...formElementParent[key]
		}
		formElement.value = event.target.value
		formElementParent[key] = formElement
		formElementRoot["opciones"][keyParent] = formElementParent
		form[keyRoot] = formElementRoot
		this.setState({ economicsFormStockExchange: form })
	}

	renderOneForm = (llave, formulario, type) => {
		let result = []
		const form = formulario
		if (type === 'currency') {
			const formKeys = form[llave]
			const formOptions = formKeys["opciones"]
			const arrayForm = Object.keys(formOptions)
			result = arrayForm.map(element =>
				<Input
					key={formOptions[element].config.id}
					htmlType={formOptions[element].htmlType}
					label={formOptions[element].label}
					config={formOptions[element].config}
					value={formOptions[element].value}
					change={(event) => this.handleChangeCurrency(event, llave, element)}
				/>
			)
		} else {
			const formKeys = form[llave]
			const formOptions = formKeys["opciones"]
			for (const element in formOptions) {
				const formElement = formOptions[element]
				const arrayForm = Object.keys(formElement)
				result.push(arrayForm.map(el =>
					<Input
						key={formElement[el].config.id}
						htmlType={formElement[el].htmlType}
						label={formElement[el].label}
						config={formElement[el].config}
						value={formElement[el].value}
						change={(event) => this.handleChangeStock(event, llave, element, el)}
					/>
				))
			}
		}
		/* const formElement = form[key]
		const arrayForm = Object.keys(formElement)
		const result = arrayForm.map(element =>
			<Input
				key={element}
				htmlType={formElement[element].htmlType}
				label={formElement[element].label}
				config={formElement[element].config}
				value={formElement[element].value}
				change={(event) => this.handleChange(event, key, element)}
			/>
		)*/
		return result
	}

	renderForms = (inicio, fin, array, size, type) => {
		const form = type === 'currency' ? this.state.economicsFormCurrency : this.state.economicsFormStockExchange
		const arrForms = []
		for (let i = inicio; i < fin; i++) {
			arrForms.push(
				<Col
					key={array[i]}
					xs={12}
					md={size}>
					<Card>
						<Card.Header><strong>{array[i].toString().toUpperCase()}</strong></Card.Header>
						<Card.Body>{this.renderOneForm(array[i], form, type)}</Card.Body>
					</Card>
				</Col>
			)
		}
		return arrForms
	}

	cleanFields = () => {
		let formElementParent = {}
		let formElement = {}
		let formElementRoot = {}
		const dataFormCurrency = {
			...this.state.economicsFormCurrency
		}
		for (const info in this.state.economicsFormCurrency) {
			formElementParent = {
				...dataFormCurrency[info]
			}
			for (const input in this.state.economicsFormCurrency[info]["opciones"]) {
				formElement = {
					...formElementParent["opciones"][input]
				}
				formElement.value = ''
				formElementParent["opciones"][input] = formElement
			}
			dataFormCurrency[info] = formElementParent
			formElement = {}
		}
		formElement = {}
		formElementParent = {}
		const dataFormStock = {
			...this.state.economicsFormStockExchange
		}
		for (const info in this.state.economicsFormStockExchange) {
			formElementRoot = {
				...dataFormStock[info]
			}
			for (const input in this.state.economicsFormStockExchange[info]["opciones"]) {
				formElementParent = {
					...formElementRoot["opciones"][input]
				}
				for (const option in this.state.economicsFormStockExchange[info]["opciones"][input]) {
					formElement = {
						...formElementParent[option]
					}
					formElement.value = ''
					formElementParent[option] = formElement
					formElement = {}
				}
				formElementRoot["opciones"][input] = formElementParent
				formElementParent = {}
			}
			dataFormStock[info] = formElementRoot
			formElementRoot = {}
		}
		this.setState({
			economicsFormCurrency: dataFormCurrency,
			economicsFormStockExchange: dataFormStock
		})
	}

	editFields = () => {
		const disabled = this.state.disabled
		const newState = !disabled
		this.setState({disabled: newState})
	}

	render() {
		const formCurrency = this.state.economicsFormCurrency
		const arrayFormCurrency = Object.keys(formCurrency)
		const formStock = this.state.economicsFormStockExchange
		const arrayFormStock = Object.keys(formStock)
		return (
			<Layout>
				<h1 className="mb-4">Formulario de Indicadores Económicos</h1>
				<Button
					variant={this.state.disabled ? "danger" : "primary"}
					className="mb-4"
					onClick={this.editFields}
				>
					{this.state.disabled ? 'Editar' : 'No editar'}
				</Button>
				<Button
					variant={this.state.disabled ? "secondary" : "danger"}
					className="ml-3 mb-4"
					onClick={this.cleanFields}
					disabled={this.state.disabled}
				>
					Limpiar todo
				</Button>
				<Form
					noValidate
					validated={this.state.validated}
					onSubmit={this.handleSubmit}
					className="mb-4"
				>
					<fieldset disabled={this.state.disabled}>
						<Row className="mb-4">{this.renderForms(0, 2, arrayFormCurrency, 6, 'currency')}</Row>
						<Row className="mb-4">{this.renderForms(0, 1, arrayFormStock, 12, 'stock')}</Row>
					</fieldset>
					<Button variant="primary" type="submit">
						Enviar
					</Button>
				</Form>
				<Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Mensaje</Modal.Title>
					</Modal.Header>
					<Modal.Body>Se editaron los datos</Modal.Body>
				</Modal>
			</Layout>
		);
	}
}

export default Economics;